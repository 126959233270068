import React from "react";
import styled, { withTheme } from "styled-components";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Redirect from "Components/Redirect";
import Loading from "Components/Loading";

import Dropdown from "Components/Dropdown";
import Button from "Components/Button";
import ButtonWrapper from "Components/ButtonWrapper";
import PlanInfo from "Components/setup/PlanInfo";
import Description from "Components/fields/Description";
import InfoDialog from "Components/InfoDialog";
import CheckMark from "Icons/CheckMark";
import ModalConfirmDelete from "Components/ModalConfirmDelete";
import Error from "Components/Error";

// @todo: move to constants file.
const planTypesMap = {
  development: "Development",
  standard: "Standard",
  medium: "Medium",
  large: "Large",
  xlarge: "Xlarge",
  "2xlarge": "2xlarge"
};

const planTypes = Object.keys(planTypesMap).map(item => ({
  value: item,
  label: planTypesMap[item]
}));

const PlanForm = styled.form`
  padding: 0 32px 32px;
  color: #38485e;
`;

const RowWrapper = styled.div`
  margin: 0 -32px;
  padding: 0 32px;
  border-bottom: 1px solid #c9d0e4;
  .dropdown {
    .input-wrapper,
    .select-box {
      width: 140px;
    }
    .select-box__control,
    .select-box:hover .select-box__control {
      background: transparent;
      border: none;
    }
    .select-box__control--is-focused {
      margin: -1px -11px 0 0;
      padding-right: 11px;
    }
    .select-box__single-value {
      width: calc(100% - 32px);
      text-align: right;
    }
    .select-box__dropdown-indicator {
      padding: 0;
      margin-right: -4px;
    }
  }
  &.total-row {
    border-bottom: none;
    .total-wrapper {
      padding-top: 24px;
      padding-bottom: 32px;
      min-height: auto;
    }
    .description {
      color: #4b6180;
      margin: 0;
      display: flex;
      align-items: center;
    }
    .info-dialog {
      cursor: default;
    }
  }
`;

const RowHeading = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  min-height: 64px;
  .label {
    font-size: 16px;
    font-weight: 700;
    line-height: 22px;
    width: 186px;
  }
  .info,
  .options {
    display: flex;
    align-items: center;
  }
  .total {
    height: 40px;
    width: 64px;
    box-sizing: border-box;
    line-height: 40px;
    padding: 0 12px;
    font-size: 16px;
    font-weight: 700;
    background: #f5f7fa;
    margin-left: 16px;
  }
`;

const IncludesWrapper = styled.div`
  .label {
    font-weight: 600;
    font-size: 15px;
    margin-bottom: 16px;
  }
  .features {
    display: flex;
    align-items: flex-start;
  }
  &.development .included ul {
    column-count: 2;
    width: 800px;
  }
`;

const FeatureList = styled.ul`
  padding: 0;
  margin: 0 auto 30px;
  list-style: none;
  width: 400px;
  max-width: 100%;
`;

const FeatureListItem = styled.li`
  padding: 0;
  font-size: 14px;
  line-height: 22px;
  min-height: 22px;
  text-align: left;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  svg {
    margin-right: 10px;
  }
  span {
    display: inline-block;
    line-height: 16px;
    &:first-letter {
      text-transform: capitalize;
    }
  }
`;

class ProjectPlanForm extends React.Component {
  constructor() {
    super();
    this.filterPlanTypes = this.filterPlanTypes.bind(this);
    this.storageOptions = this.storageOptions.bind(this);
    this.gig = 1024;
    // @todo: move to utility function.
    this.storageSuffix = "GB";
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.updateEstimation = this.updateEstimation.bind(this);

    this.state = {
      isChanged: false,
      openModal: false,
      subscription: {},
      estimate: {
        plan: "",
        environments: "",
        storage: "",
        total: "",
        options: {}
      }
    };
  }

  componentDidMount() {
    if (this.props.project) {
      const subscriptionId = this.props.project.getSubscriptionId();
      this.props.loadSubscription(subscriptionId);
    }
  }

  componentDidUpdate(prevProps) {
    const projId = this.props.project ? this.props.project.id : null;
    const prevProjId = prevProps.project ? prevProps.project.id : null;
    const shouldLoadSubscription =
      projId !== prevProjId &&
      this.props.subscription === prevProps.subscription;

    if (shouldLoadSubscription) {
      const subscriptionId = this.props.project.getSubscriptionId();
      this.props.loadSubscription(subscriptionId);
    }
  }

  filterPlanTypes(planTypes) {
    if (this.props.subscription.plan !== "development") {
      return planTypes.filter(item => item.value !== "development");
    }
    return planTypes;
  }

  environmentOptions() {
    // @todo: make it so you can downgrade environments up to how many envs you have setup, and environments are listed by 3.
    const { environments } = this.props;

    if (environments && environments.size > 0) {
      const environmentsInUse = environments.size;
      const numberOfEnvFloor = environmentsInUse + 3 - (environmentsInUse % 3);
      let list = [];
      for (let i = numberOfEnvFloor; i <= 21; i += 3) {
        list.push({ value: i, label: i });
      }
      return list;
    }
  }

  storageOptions(currentStorage) {
    const currentStorageGig = currentStorage / this.gig;

    let storageOptions = [];
    for (let i = currentStorageGig; i <= 50; i += 5) {
      storageOptions.push({
        value: i * this.gig,
        label: `${i + this.storageSuffix}`
      });
    }

    return storageOptions;
  }

  async initalizeEstimation() {
    const { subscription } = this.props;
    const { plan, environments, storage, user_licenses } = subscription;
    const estimate = await this.props.subscription.getEstimate();
    this.setState({
      estimate: { ...estimate },
      subscription: { plan, environments, storage, user_licenses } // only select elements from this.
    });
  }

  async updateEstimation() {
    const subscriptionFromState = this.state.subscription;
    const platformLib = await import("Libs/platform");
    const client = platformLib.default;
    const estimateToSave = await client.getSubscriptionEstimate(
      subscriptionFromState.plan,
      subscriptionFromState.storage,
      subscriptionFromState.environments,
      subscriptionFromState.user_licenses
    );

    this.setState({ estimate: { ...estimateToSave } });
  }

  handleChange(event, field) {
    this.setState({
      subscription: {
        [field]: event.value
      },

      isChanged: true
    });

    this.updateEstimation();
  }

  handleSubmit() {
    this.props.updateSubscription(
      { ...this.state.subscription },
      this.props.subscription
    );
    if (!this.props.error) {
      this.setState({
        isChanged: false
      });
    }
  }

  handleCancel() {
    this.setState({
      plan: this.props.subscription.plan,
      storage: this.props.subscription.storage,
      environments: this.props.subscription.environments,
      isChanged: false
    });
  }

  openModal() {
    this.setState({
      openModal: true
    });
  }

  closeModal() {
    this.setState({
      openModal: false
    });
  }

  render() {
    const { subscription, loading, error } = this.props;
    const estimate = this.state.estimate || {};

    if (subscription && !this.state.estimate.total) {
      this.initalizeEstimation();
    }

    return (
      <PlanForm
        onSubmit={event => {
          event.preventDefault();
          this.handleSubmit(event);
        }}
      >
        {error && <Error>{error}</Error>}
        {loading && <Loading />}
        {this.props.deletedSubscription && <Redirect to={"/"} />}
        {subscription && (
          <React.Fragment>
            <RowWrapper>
              <RowHeading>
                <div className="info">
                  <div className="label">Plan type</div>
                </div>
                <div className="options">
                  <Dropdown
                    options={this.filterPlanTypes(planTypes)}
                    defaultValue={{
                      value: planTypesMap[subscription.plan].toLowerCase(),
                      label: planTypesMap[subscription.plan]
                    }}
                    onChange={event => this.handleChange(event, "plan")}
                    clearable={false}
                    error={this.props.error}
                    fieldType={true}
                    required={true}
                  />
                  <div className="total">{estimate.plan}</div>
                </div>
              </RowHeading>
              <IncludesWrapper
                className={
                  this.state.subscription.plan === "development"
                    ? "development"
                    : ""
                }
              >
                <div className="label">Includes</div>
                <div className="features">
                  <div className="included">
                    <FeatureList>
                      <FeatureListItem>
                        <CheckMark color={this.props.theme.buttonBg} /> 1 User
                      </FeatureListItem>
                      <FeatureListItem>
                        <CheckMark color={this.props.theme.buttonBg} /> 3
                        Development environments
                      </FeatureListItem>
                      <FeatureListItem>
                        <CheckMark color={this.props.theme.buttonBg} /> 5GB
                        storage per environment
                      </FeatureListItem>
                      <FeatureListItem>
                        <CheckMark color={this.props.theme.buttonBg} /> Master
                        dev site (future production site)
                      </FeatureListItem>
                    </FeatureList>
                  </div>
                  <PlanInfo plan={this.state.subscription.plan} />
                </div>
              </IncludesWrapper>
            </RowWrapper>
            <RowWrapper>
              <RowHeading>
                <div className="info">
                  <div className="label">Environments</div>
                  <div className="price">
                    {estimate.options.environments} / 3 environments
                  </div>
                </div>
                <div className="options">
                  <Dropdown
                    options={this.environmentOptions(subscription.environments)}
                    defaultValue={{
                      value: subscription.environments,
                      label: subscription.environments
                    }}
                    onChange={event => this.handleChange(event, "environments")}
                    clearable={false}
                    error={this.props.error}
                    fieldType={true}
                    required={true}
                  />
                  <div className="total">{estimate.environments}</div>
                </div>
              </RowHeading>
            </RowWrapper>
            <RowWrapper>
              <RowHeading>
                <div className="info">
                  <div className="label">Storage</div>
                  <div className="price">
                    {estimate.options.storage} / 5 GB per environment
                  </div>
                </div>
                <div className="options">
                  <Dropdown
                    options={this.storageOptions(subscription.storage)}
                    defaultValue={{
                      value: subscription.storage,
                      label:
                        `${subscription.storage / this.gig}` +
                        this.storageSuffix
                    }}
                    onChange={event => this.handleChange(event, "storage")}
                    clearable={false}
                    error={this.props.error}
                    fieldType={true}
                    required={true}
                  />
                  <div className="total">{estimate.storage}</div>
                </div>
              </RowHeading>
            </RowWrapper>
            <RowWrapper>
              <RowHeading>
                <div className="info">
                  <div className="label">Users</div>
                  <div className="price">
                    {estimate.options.user_licenses} / per user per month.
                    Billed when added to your platform
                  </div>
                </div>
                <div className="options">
                  <div className="users">{subscription.user_licenses}</div>
                  <div className="total">{estimate.user_licenses}</div>
                </div>
              </RowHeading>
            </RowWrapper>
            <RowWrapper className="total-row">
              <RowHeading className="total-wrapper">
                <div className="info" />
                <div className="options">
                  <Description className="description">
                    Total monthly cost* <InfoDialog />
                  </Description>
                  <div className="total">{estimate.total}</div>
                </div>
              </RowHeading>
            </RowWrapper>
            <ButtonWrapper>
              {this.state.isChanged && (
                <React.Fragment>
                  <Button type="submit">Save plan</Button>
                  <Button
                    type="button"
                    className="secondary"
                    onClick={this.handleCancel}
                  >
                    Cancel
                  </Button>
                </React.Fragment>
              )}
              <Button
                type="button"
                className="outline"
                onClick={this.openModal}
              >
                Delete plan
              </Button>
            </ButtonWrapper>
          </React.Fragment>
        )}
        <ModalConfirmDelete
          title="Delete project"
          deleteFunction={() => this.props.deleteSubscription(subscription)}
          isOpen={this.state.openModal}
          closeModal={this.closeModal}
          itemId={`subscription-${subscription && subscription.id}`}
          size="medium"
        >
          <p>
            Are you sure you want to delete your project{" "}
            <strong>{subscription && subscription.project_title}</strong>
          </p>
          <p>
            Please note that deleting your project is irreversible and all data
            associated with this project will be deleted, including backups.
            Lastly, at the end of the month you will be charged for any
            remaining project costs.
          </p>
        </ModalConfirmDelete>
      </PlanForm>
    );
  }
}

ProjectPlanForm.propTypes = {
  project: PropTypes.object,
  subscription: PropTypes.object,
  loadSubscription: PropTypes.func,
  environments: PropTypes.number,
  updateSubscription: PropTypes.func,
  deleteSubscription: PropTypes.func,
  error: PropTypes.string,
  theme: PropTypes.object,
  loading: PropTypes.bool,
  deletedSubscription: PropTypes.object
};

const mapStateToProps = (state, props) => {
  const project = state.project || new Map();
  const subscription = state.subscription || new Map();
  const environment = state.environment || new Map();

  // @todo: add loading state for loading and updating subscription.
  return {
    project: project.getIn(["data", props.organizationId, props.projectId]),
    loading: subscription.get("loading"),
    error: state.subscription.get("error", ""),
    subscription: subscription.getIn([
      "data",
      props.organizationId,
      props.projectId
    ]),
    deletedSubscription: subscription.get("deleted"),
    environments: environment.getIn([
      "data",
      props.organizationId,
      props.projectId
    ])
  };
};

const mapDispatchToProps = dispatch => ({
  loadSubscription: id =>
    import("Reducers/subscription").then(reducer => {
      dispatch(reducer.loadSubscription(id));
    }),
  updateSubscription: (updates, subscription) =>
    import("Reducers/subscription").then(reducer => {
      dispatch(reducer.updateSubscription(updates, subscription));
    }),
  deleteSubscription: subscription =>
    import("Reducers/subscription").then(reducer => {
      dispatch(reducer.deleteSubscription(subscription));
    })
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTheme(ProjectPlanForm));
