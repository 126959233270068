import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import ProjectPlanForm from "Components/ProjectPlanForm";
import ErrorBoundary from "Components/ErrorBoundary";
import ContentLayout from "Components/ContentLayout";
import Heading2 from "Components/styleguide/Heading2";
import ButtonLink from "Components/ButtonLink";
import ListGroup from "Components/ListGroup";
import Description from "Components/fields/Description";

const HeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 24px;
  h2 {
    margin: 0;
  }
`;

const ProjectDetail = props => (
  <ContentLayout className="settings-content">
    <ErrorBoundary>
      <HeaderWrapper>
        <Heading2 id="plan-heading">Plan</Heading2>
        <ButtonLink
          to={`/${props.params.organizationId}/billing/history`}
          className="outline"
          text="View subscription"
        />
      </HeaderWrapper>
      <ListGroup>
        <ProjectPlanForm {...props.params} />
      </ListGroup>
      <Description>
        * Note that you can only upgrade environments and storage at this time.
        Downgrades can be requested by submitting a support ticket.
      </Description>
    </ErrorBoundary>
  </ContentLayout>
);

ProjectDetail.propTypes = {
  project: PropTypes.shape({
    id: PropTypes.string.isRequired
  }),
  params: PropTypes.shape({
    projectId: PropTypes.string.isRequired,
    organizationId: PropTypes.string.isRequired,
    environmentId: PropTypes.string
  })
};

export default ProjectDetail;
